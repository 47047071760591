<template>
  <div class="dashboard-container">
    <div class="action-buttons d-flex justify-content-between py-5">
      <div>
        <div v-if="currentItem.type === 'project'">
          <modal-component-page
            v-if="groupDomain && $hasPrivileges(
              $privileges('CAN_CREATE_PAGES'),
              userObject.isSysAdmin,
              currentItem.privilegesForItem,
            )"
            :item-position="pagePosition"
            :child-slugs="slugsOfAllChildItems"
            :parent-item="currentItem"
            :group-id="groupId"
            :group-slug="groupSlug"
            :group-domain="groupDomain"
            :group-path="groupPath"
            :email="userObject.email"
          ></modal-component-page>

          <b-button
            data-dashboard-step="3"
            v-if="$hasPrivileges(
              $privileges('CAN_CREATE_PAGES'),
              userObject.isSysAdmin,
              currentItem.privilegesForItem
            )"
            v-b-modal.modal-component-page class="green-button-transparent mr-3 border-0"
          >
            <b-icon
              icon="plus-circle-fill"
              class="mr-2 top--3"
              aria-hidden="true"
              scale="1"
            ></b-icon>
            {{ $t('webmag.page_add') }}
          </b-button>
        </div>
        <div v-else>
          <modal-choose-item-type
            v-if="currentItem && groupDomain &&
              ($hasPrivileges(
                $privileges('CAN_CREATE_PAGES'),
                userObject.isSysAdmin,
                currentItem.privilegesForItem
              ) ||
              $hasPrivileges(
                $privileges('CAN_CREATE_PROJECTS'),
                userObject.isSysAdmin,
                currentItem.privilegesForItem)
              )"
            class="d-inline-block"
            :parent-item="currentItem"
            :pagePosition="pagePosition"
            :folderProjectPosition="folderProjectPosition"
            :child-slugs="slugsOfAllChildItems"
            :group-id="groupId"
            :group-slug="groupSlug"
            :group-path="groupPath"
            :group-domain="groupDomain"
            :is-sys-admin="userObject.isSysAdmin"
            :email="userObject.email"
            @insert-data="refreshItemsQuery"
          >
          </modal-choose-item-type>
          <modal-create-new-folder
            v-if="currentItem && groupDomain
            && $hasPrivileges(
              $privileges('CAN_CREATE_FOLDERS'),
              userObject.isSysAdmin,
              currentItem.privilegesForItem
            )"
            class="d-inline-block"
            :folder-project-position="folderProjectPosition"
            :child-slugs="slugsOfAllChildItems"
            :group-id="groupId"
            :group-slug="groupSlug"
            :group-path="groupPath"
            :group-domain="groupDomain"
            :parent-id="currentItem.id"
            :email="userObject.email"
            @insert-data="refreshItemsQuery"
          ></modal-create-new-folder>
        </div>
      </div>
      <div class="table-search d-flex">
        <b-input-group class="search-group d-flex align-items-center mr-2">
          <b-icon
            icon="search"
            aria-hidden="true"
            scale="1"
          ></b-icon>
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            @input="searchFieldTriggered"
            :placeholder="$t('webmag.search')"
          ></b-form-input>
        </b-input-group>
        <b-button
          class="mr-2 dark-grey-buttons"
          :class="{active: !isTableView }"
          @click="changeDashboardView('cards')"
        >
          <b-icon
            icon="grid3x3-gap-fill"
            aria-hidden="true"
            class="top--1"
            font-scale="1.1"
            scale="1.1"
          ></b-icon>
        </b-button>
        <b-button
          class="dark-grey-buttons"
          :class="{active: isTableView }"
          @click="changeDashboardView('table')"
        >
          <b-icon
            icon="list-task"
            aria-hidden="true"
            class="top--1"
            font-scale="1.1"
            scale="1.1"
          ></b-icon>
        </b-button>
      </div>
    </div>
    <div class="pb-2 d-flex flex-wrap" v-if="currentItem.type !== 'project'">
      <b-table
        v-if="projectAndFolderItems.length > 0"
        ref="projectAndFolderTable"
        :key="projectAndFolderTableKey"
        :fields="fields"
        :items="projectAndFolderItems"
        :filter="filter"
        :filter-included-fields="filterOn"
        v-sortable="{ items: projectAndFolderItems, table: 'projectAndFolder' }"
        show-empty
        :empty-filtered-html="$t('webmag.emptyFilteredText')"
        borderless
        dark
        fixed
        @head-clicked="sortedProjectAndFolderTable"
        @sort-changed="sortProjectAndFolderChanged"
        @row-clicked="navigateToItem"
        :table-class="{ 'table-view': isTableView, 'card-view': !isTableView}"
      >
        <template #table-colgroup="scope" v-if="isTableView">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.width }"
          >
        </template>
        <template #cell(image)="data">
          <div
            v-if="data.item.type === 'folder'"
            class="cell-folder cell-background-image d-flex
            justify-content-center align-items-center"
          >
            <b-icon
              icon="folder"
              aria-hidden="true"
              scale="1"
            ></b-icon>
          </div>
          <div
            v-if="data.item.type === 'project' && !data.item.image"
            class="cell-background-image d-flex justify-content-center align-items-center"
          >
            <b-icon
              icon="files"
              aria-hidden="true"
              scale="1"
            ></b-icon>
          </div>
          <div
            v-if="data.item.type === 'project' && data.item.image"
            class="cell-background-image"
            :style="`background-image: ${data.item.image}`"
          >
          </div>
        </template>
        <template #cell(order)="data">
          <span :id="data.item.slug">
            {{ data.item.order | formatOrder }}
          </span>
        </template>
        <template #cell(name)="data">
          {{ data.item.name }}
        </template>
        <template #cell(tags)="data">
          <ul
            v-if="data.item.tags"
            class="d-flex align-items-start"
            :id="`tag-row-${data.item.type}-${data.item.id}`"
          >
            <li
              v-for="(item, index) in data.item.tags"
              :key="'tag-' + index">
              {{ item }}
            </li>
          </ul>
          <b-tooltip
            v-if="showTooltipOfTags(data.item.tags)"
            :target="`tag-row-${data.item.type}-${data.item.id}`"
          >
            <ul
              class="tags-tooltip"
              v-if="data.item.tags"
            >
              <li
                v-for="(item, index) in data.item.tags"
                :key="'tag-' + index">
                {{ item }}
              </li>
            </ul>
          </b-tooltip>
        </template>
        <template #cell(lastUpdated)="data">
          {{ data.item.lastUpdated | dateFormat }}
        </template>
        <template #cell(created)="data">
          {{ data.item.created | dateFormat }}
        </template>
        <template #cell(status)="data">
          <div
            class="d-flex justify-content-start"
            v-if="data.item.type !== 'folder'
              && (!data.item.incompatibleItem || userObject.isSysAdmin)"
          >
            <publish-status-icons
              :item="data.item"
              id="dashboard"
              :key="`publishIcon-${data.item.id}-${counter}`"
            ></publish-status-icons>
            <searchable-status-icons
              :item="data.item"
              id="dashboard"
            ></searchable-status-icons>
            <status-icons
              :has-access-to-settings="canAccessFolderOrProjectSettings"
              :item="data.item"
              id="dashboard"
            ></status-icons>
          </div>
          <div v-else>
            <listed-status-icons
              :has-access-to-settings="canAccessFolderOrProjectSettings"
              :item="data.item"
              id="dashboard"
            ></listed-status-icons>
          </div>
        </template>
        <template #cell(dot)="data">
          <div v-if="data.item.incompatibleItem" class="pt-2">
            <incompatible-icon
              :item="data.item"
              id="dashboard"
            ></incompatible-icon>
          </div>
          <dot-dropdown
            v-if="isTableView && (data.item.incompatibleItem !== true || userObject.isSysAdmin)"
            :item="data.item"
            :parent-item="currentItem"
            :group-slug="groupSlug"
            :is-group-user="userObject.isGroupUser"
            :is-sys-admin="userObject.isSysAdmin"
            :key="rerenderProjectAndFolderDropdownKey"
            @item-action="itemAction"
          />
        </template>
        <template #cell(cards)="data">
          <dashboard-item
            v-if="!isTableView"
            :item="data.item"
            :parent-item="currentItem"
            :has-access-to-settings="canAccessFolderOrProjectSettings"
            :group-slug="groupSlug"
            :is-group-user="userObject.isGroupUser"
            :is-sys-admin="userObject.isSysAdmin"
            @update-item="refreshItemsQuery"
            @item-action="itemAction"
          />
          <modal-incompatible-item
            v-if="data.item.incompatibleItem && !userObject.isSysAdmin"
            ref="incompatibleModalComponent"
            :item="data.item"
            :group-slug="groupSlug"
            :slug="data.item.slug"
          ></modal-incompatible-item>
        </template>
      </b-table>
    </div>
    <div class="d-flex flex-wrap">
      <b-table
        v-if="pageItems.length > 0"
        ref="pageTable"
        :key="pageTableKey"
        :fields="fields"
        :items="pageItems"
        v-sortable="{ items: pageItems, table: 'pages' }"
        :filter="filter"
        :filter-included-fields="filterOn"
        @head-clicked="sortedPageTable"
        @sort-changed="sortPageChanged"
        borderless
        show-empty
        :empty-filtered-html="$t('webmag.emptyFilteredText')"
        dark
        fixed
        @row-clicked="navigateToItem"
        :table-class="{ 'table-view': isTableView, 'card-view': !isTableView}"
      >
        <template #table-colgroup="scope" v-if="isTableView">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.width }"
          >
        </template>
        <template #cell(image)="data">
          <div
            class="cell-background-image"
            :style="{ backgroundImage: data.item.image, backgroundPosition: data.item.focalpoint }"
          >
          </div>
        </template>
        <template #cell(order)="data">
          <span :id="data.item.slug">
            {{ data.item.order | formatOrder }}
          </span>
        </template>
        <template #cell(name)="data">
          {{ data.item.name }}
        </template>
        <template #cell(tags)="data">
          <ul v-if="data.item.tags" class="d-flex">
            <li v-for="(item, index) in data.item.tags" :key="'tag-' + index">
              {{ item }}
            </li>
          </ul>
        </template>
        <template #cell(keywords)="data">
          <ul v-if="data.item.keywords" class="d-flex">
            <li v-for="(item, index) in data.item.keywords" :key="'keyword-' + index">
              {{ item }}
            </li>
          </ul>
        </template>
        <template #cell(lastUpdated)="data">
          <div :id="`tooltip-updated-by-${data.item.slug}`">
            {{ data.item.lastUpdated | dateFormat }}
          </div>
          <b-tooltip
            v-if="data.item.updatedBy"
            :target="`tooltip-updated-by-${data.item.slug}`"
          >
            <small>{{ $t('webmag.dashboardItems.lastUpdatedBy') }} {{ data.item.updatedBy }}</small>
          </b-tooltip>
        </template>
        <template #cell(created)="data">
          {{ data.item.created | dateFormat }}
        </template>
        <template #cell(status)="data">
          <div class="d-flex justify-content-start">
            <publish-status-icons
              :item="data.item"
              :key="`publishIcon-${data.item.id}-${counter}`"
            ></publish-status-icons>
            <status-icons
              :has-access-to-settings="canAccessPageSettings"
              :item="data.item"
              :parent-item-type="currentItem.type"
              id="dashboard"
            ></status-icons>
          </div>
        </template>
        <template #cell(dot)="data">
          <dot-dropdown
            v-if="isTableView && (data.item.incompatibleItem !== true || userObject.isSysAdmin)"
            :item="data.item"
            :key="rerenderPageTableDropdownKey"
            :parent-item="currentItem"
            :group-slug="groupSlug"
            :is-project-published="isProjectPublished"
            :is-group-user="userObject.isGroupUser"
            :is-sys-admin="userObject.isSysAdmin"
            @item-action="itemAction"
          />
        </template>
        <template #cell(cards)="data">
          <dashboard-item
            v-if="!isTableView"
            :item="data.item"
            :parent-item="currentItem"
            :group-slug="groupSlug"
            :has-access-to-settings="canAccessPageSettings"
            :is-project-published="isProjectPublished"
            :is-group-user="userObject.isGroupUser"
            :is-sys-admin="userObject.isSysAdmin"
            @update-item="refreshItemsQuery"
            @item-action="itemAction"
          />
        </template>
      </b-table>
    </div>
    <modal-preview-page
      v-if="isPreviewWindowOpen && iframeSourceForPreview && currentItem.name"
      :iframe-src="iframeSourceForPreview"
      :preview-action-object="previewActionObject"
      :itemName="currentItem.name"
      :group-domain="groupDomain"
      :item-path="currentItem.itemPath"
    />
    <modal-show-publish-success
      v-if="showPublishSuccessModal && groupDomain"
      :slug="publishedItem.slug"
      :group-slug="groupSlug"
      :group-domain="groupDomain"
      :slug-path="publishedItem.slugPath"
      :pageType="publishedItem.type"
      @modal-confirmed="showPublishSuccessModal = false"
    ></modal-show-publish-success>
    <modal-publish-item
      v-if="currentItem.type === 'project' && showPublishModal"
      :item="{ slug: slugOfPage }"
      @publish-item="publishItemFromHeaderCall"
      @publish-cancel="publishItemCancel"
    ></modal-publish-item>
    <modal-show-url
      v-if="showShowUrlModal && groupDomain && currentItem.type !== 'folder'"
      :slug="slugOfPage"
      :group-domain="groupDomain"
      :slug-path="`${parentSlugPath}/${slugOfPage}`"
      :pageType="currentItem.type"
      :itemPath="currentItem.itemPath"
      @close-modal="closeShowUrlModal"
    ></modal-show-url>
  </div>
</template>

<script>
import DashboardItem from '@/components/dashboard/DashboardItem.vue';
import DotDropdown from '@/components/dashboard/DotDropdown.vue';
import PublishStatusIcons from '@/components/dashboard/PublishStatusIcons.vue';
import SearchableStatusIcons from '@/components/dashboard/SearchableStatusIcons.vue';
import StatusIcons from '@/components/dashboard/StatusIcons.vue';
import ListedStatusIcons from '@/components/dashboard/ListedStatusIcons.vue';
import ModalComponentPage from '@/components/modals/ModalComponentPage.vue';
import ModalIncompatibleItem from '@/components/modals/ModalIncompatibleItem.vue';
import DeleteItem from '@/graphQlQueries/mutations/deleteItem';
import SetItemUrlWindowFromCache from '@/graphQlQueries/mutations/setItemUrlWindowFromCache';
import SetLoadingIndicatorInCache from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import SetPublishStateOfPageById from '@/graphQlQueries/mutations/setPublishStateOfPageById';
import SetPublishStateOfProject from '@/graphQlQueries/mutations/setPublishStateOfProject';
import UpdatePagePublishFlag from '@/graphQlQueries/mutations/updatePagePublishFlag';
import InsertPublicationLogs from '@/graphQlQueries/mutations/insertPublicationLogs';
import UpdatePageVersionsPublishState from '@/graphQlQueries/mutations/updatePageVersionsPublishState';
import GetAllChildPagesOfProject from '@/graphQlQueries/queries/getAllChildPagesOfProject';
// import graphQL queries
import GetAllItemsOfFolderById from '@/graphQlQueries/queries/getAllItemsOfFolderById';
import GetAllItemsOfProjectById from '@/graphQlQueries/queries/getAllItemsOfProjectById';
import GetGroupSlugByGroupId from '@/graphQlQueries/queries/getGroupSlugByGroupId';
import GetAllChildPageIdsOfProject from '@/graphQlQueries/queries/getAllChildPageIdsOfProject';
import SetOrderOfPagesOnDashboard from '@/graphQlQueries/mutations/setOrderOfPages';
import SetOrderOfFoldersOnDashboard from '@/graphQlQueries/mutations/setOrderOfFolders';
import SetOrderOfProjectsOnDashboard from '@/graphQlQueries/mutations/setOrderOfProjects';
import SetProjectHasToBePublishedInCache from '@/graphQlQueries/mutations/setProjectHasToBePublishedInCache';
import DeleteCacheFetchMagazine from '@/graphQlQueries/mutations/deleteCacheFetchMagazine';
import GetAllAvailableLanguages from '@/graphQlQueries/queries/getAllAvailableLanguages';
import GetPageDataToPublish from '@/graphQlQueries/queries/getPageDataToPublish';
import GetItemName from '@/graphQlQueries/queries/getItemName';
import GetPreviewWindowFromCache from '@/graphQlQueries/queries/getPreviewWindowFromCache';
import GetProjectHasToBePublishedFromCache from '@/graphQlQueries/queries/getProjectHasToBePublishedFromCache';
import GetProjectWithChildPagesDataToPublish from '@/graphQlQueries/queries/getProjectWithChildPagesDataToPublish';
import GetPublishStatusOfPageVersions from '@/graphQlQueries/queries/getPublishStatusOfPageVersionsByProjectId';
import GetProjectPublishStatus from '@/graphQlQueries/queries/getProjectPublishStatus';
import SendPublishedDataToServer from '@/mixins/sendPublishedDataToServer';
import DashboardTourMixin from '@/mixins/tour/dashboardTourMixin';
import DashboardItemTourMixin from '@/mixins/tour/dashboardItemTourMixin';
import Sortable from 'sortablejs';
import GetItemUrlWindowFromCache from '@/graphQlQueries/queries/getItemUrlWindowFromCache';

export default {
  name: 'Dashboard',
  mixins: [SendPublishedDataToServer, DashboardTourMixin, DashboardItemTourMixin],
  components: {
    ModalIncompatibleItem,
    SearchableStatusIcons,
    StatusIcons,
    IncompatibleIcon: () => import('@/components/dashboard/IncompatipleIcon.vue'),
    ListedStatusIcons,
    DotDropdown,
    PublishStatusIcons,
    ModalComponentPage,
    DashboardItem,
    ModalChooseItemType: () => import('@/components/modals/ModalChooseItemType.vue'),
    ModalCreateNewFolder: () => import('@/components/modals/ModalCreateNewFolder.vue'),
    ModalPreviewPage: () => import('@/components/modals/ModalPreviewPage.vue'),
    ModalPublishItem: () => import('@/components/modals/ModalPublishItem.vue'),
    ModalShowPublishSuccess: () => import('@/components/modals/ModalShowPublishSuccess.vue'),
    ModalShowUrl: () => import('@/components/modals/ModalShowUrl.vue'),
  },
  props: {
    currentItem: { type: Object },
    projectId: { type: Number },
    groupId: { type: Number },
    userObject: { type: Object },
    allAvailablePrivileges: { type: Array },
  },
  directives: {
    sortable: {
      bind(el, binding, vnode) {
        if (vnode.context.$hasPrivileges(
          vnode.context.$privileges('CAN_SORT_ITEMS'),
          vnode.context.userObject.isSysAdmin,
          vnode.context.currentItem.privilegesForItem,
        )) {
          const tableName = binding.value.table;
          // eslint-disable-next-line no-param-reassign
          vnode.context.sortableObject = Sortable.create(el.querySelector('tbody'), {
            ...binding.value.items,
            vnode,
            disabled: false,
            onMove: () => {
              if (tableName === 'pages') {
                return !!vnode.context.dragAndDropForPageTableAllowed;
              }
              return !!vnode.context.dragAndDropForFoldersAndProjectsTableAllowed;
            },
            onEnd: () => {
              if (
                (tableName === 'pages' && vnode.context.dragAndDropForPageTableAllowed)
                || (tableName === 'projectAndFolder' && vnode.context.dragAndDropForFoldersAndProjectsTableAllowed)
              ) {
                const allSlugs = el.querySelectorAll('span[id]');
                const newOrderedItemsForDbUpdate = [];
                const newOrderedItemsForTableUpdate = [];
                allSlugs.forEach((slug, index) => {
                  const currentSlug = slug.id;
                  let currentItem = null;
                  if (tableName === 'pages') {
                    // eslint-disable-next-line prefer-destructuring
                    [currentItem] = vnode.context.pageItems.filter(
                      (item) => item.slug === currentSlug,
                    );
                    currentItem.order = index + 1;
                    newOrderedItemsForTableUpdate.push(currentItem);
                    newOrderedItemsForDbUpdate.push({
                      page_id: currentItem.id,
                      order_index: currentItem.order,
                      group_id: currentItem.group_id,
                      slug: currentItem.slug,
                    });
                  }
                  if (tableName === 'projectAndFolder') {
                    [currentItem] = vnode.context.projectAndFolderItems.filter(
                      (item) => item.slug === currentSlug,
                    );
                    currentItem.order = index + 1;
                    newOrderedItemsForTableUpdate.push(currentItem);
                    newOrderedItemsForDbUpdate.push({
                      item_id: currentItem.id,
                      type: currentItem.type,
                      order_index: currentItem.order,
                      group_id: currentItem.group_id,
                      slug: currentItem.slug,
                    });
                  }
                });
                if (tableName === 'pages') {
                  vnode.context.sortThePageArrayAfterDragAndDrop(
                    newOrderedItemsForDbUpdate,
                    newOrderedItemsForTableUpdate,
                  );
                }
                if (tableName === 'projectAndFolder') {
                  vnode.context.sortTheProjectAndFolderArrayAfterDragAndDrop(
                    newOrderedItemsForDbUpdate,
                    newOrderedItemsForTableUpdate,
                  );
                }
              } else {
                vnode.context.makeToast(tableName);
              }
            },
          });
        }
      },
    },
  },
  data() {
    return {
      rerenderPageTableDropdownKey: 1,
      rerenderProjectAndFolderDropdownKey: 1,
      showPublishSuccessModal: false,
      showPublishModal: false,
      showShowUrlModal: false,
      publishedItem: null,
      publishTriggeredFromDotDropdown: false,
      updateProject: false,
      toastCount: 0,
      pageTableKey: 0,
      projectAndFolderTableKey: 0,
      sortableObject: null,
      isTableView: false,
      filter: null,
      filterOn: ['name', 'tags', 'keywords'],
      counter: 0,
      isPreviewWindowOpen: false,
      slugOfPage: null,
      items: null,
      projectAndFolderItems: [],
      pageItems: [],
      pageTableIsSorted: false,
      folderAndProjectTableIsSorted: false,
      tablesAreFiltered: false,
      slugsOfAllChildItems: [],
      groupDomain: null,
      groupSlug: null,
      groupPath: null,
      parentSlugPath: null,
      numberOfProjectsAndFoldersOnCurrentItem: null,
      numberOfPagesOnCurrentItem: null,
      iframeSourceForPreview: null,
      previewActionObject: null,
      doPublishTheSelectedItem: false,
      selectedItemForAction: null,
      statusOfPageVersions: null,
      isProjectPublished: true,
      isProjectPublishedQuery: null,
      publishChangedProjectData: false,
      publishedChildPageIdsOfDB: [],
      queryChildPagesOfProject: null,
      allAvailableLanguages: null,
      itemName: null,
      fields: [
        {
          key: 'image',
          label: '',
          sortable: false,
          width: '50px',
          tdClass: 'image_column',
        },
        {
          key: 'order',
          label: this.$t('dashboard.table.order'),
          sortable: true,
          width: '80px',
          tdClass: 'order_column',
        },
        {
          key: 'name',
          label: this.$t('dashboard.table.name'),
          sortable: true,
          width: 'auto',
          tdClass: 'name_column',
        },
        {
          key: 'tags',
          label: this.$t('dashboard.table.tags'),
          sortable: false,
          width: 'auto',
          tdClass: 'tags_column',
        },
        {
          key: 'keywords',
          label: this.$t('dashboard.table.keywords'),
          sortable: false,
          width: 'auto',
          tdClass: 'keywords_column',
        },
        {
          key: 'lastUpdated',
          label: this.$t('dashboard.table.lastUpdate'),
          sortable: true,
          width: '120px',
          tdClass: 'lastUpdated_column',
        },
        {
          key: 'created',
          label: this.$t('dashboard.table.created'),
          sortable: true,
          width: '120px',
          tdClass: 'created_column',
        },
        {
          key: 'status',
          label: this.$t('dashboard.table.status'),
          sortable: false,
          width: '75px',
          tdClass: 'status_column',
        },
        {
          key: 'dot',
          label: '',
          sortable: false,
          width: '40px',
        },
        {
          key: 'cards',
          label: '',
          sortable: false,
          width: '0',
        },
      ],
    };
  },
  filters: {
    formatOrder(value) {
      return (value < 10) ? `0${value}.` : `${value}.`;
    },
    dateFormat(jsDateString) {
      const date = new Date(jsDateString);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const fullYear = date.getFullYear();

      return `${day <= 9 ? `0${day}` : day}.${month <= 9 ? `0${month}` : month}.${fullYear}`;
    },
  },
  computed: {
    dragAndDropForPageTableAllowed() {
      return (!this.pageTableIsSorted && !this.tablesAreFiltered);
    },
    dragAndDropForFoldersAndProjectsTableAllowed() {
      return (!this.folderAndProjectTableIsSorted && !this.tablesAreFiltered);
    },
    pagePosition() {
      return this.pageItems.length + 1;
    },
    folderProjectPosition() {
      return this.projectAndFolderItems.length + 1;
    },
    canAccessPageSettings() {
      return this.$hasPrivileges(
        this.$privileges('CAN_EDIT_PAGE_SETTINGS'),
        this.userObject.isSysAdmin,
        this.currentItem.privilegesForItem,
      );
    },
    canAccessFolderOrProjectSettings() {
      const canAccessFolderPage = this.$hasPrivileges(
        this.$privileges('CAN_EDIT_FOLDER_SETTINGS'),
        this.userObject.isSysAdmin,
        this.currentItem.privilegesForItem,
      );
      const canAccessProjectPage = this.$hasPrivileges(
        this.$privileges('CAN_EDIT_PROJECT_SETTINGS'),
        this.userObject.isSysAdmin,
        this.currentItem.privilegesForItem,
      );
      return canAccessFolderPage || canAccessProjectPage;
    },
  },
  created() {
    this.isTableView = this.$localStorage.get('isDashboardTableView');
    // get the slug by taking the last item of the url parameter
    this.slugOfPage = (this.$route.params.parametersOfUrl)
      ? this.$route.fullPath.split('/').slice(-1).pop()
      : null;
    this.parentSlugPath = (this.$route.params.parametersOfUrl)
      ? this.$route.fullPath.substring(
        0, this.$route.fullPath.lastIndexOf('/'),
      )
      : null;
  },
  mounted() {
    this.dashboardTour();
    // Refresh items from all components with this.$root.$emit('insertData');
    this.$root.$on('insertData', () => {
      this.refreshItemsQuery();
    });
  },
  apollo: {
    items: {
      query() {
        if (this.currentItem.id && this.currentItem.type === 'folder') {
          return GetAllItemsOfFolderById;
        }
        return GetAllItemsOfProjectById;
      },
      variables() {
        return { id: this.currentItem.id };
      },
      update(data) {
        this.slugsOfAllChildItems = [];
        this.projectAndFolderItems = [];
        this.pageItems = [];
        let itemsArray = [];
        let pageOrder = 1;
        const { userGroupRoleIds } = this.userObject;
        if (data.folders && data.folders.length > 0) {
          const groupId = data.folders[0].group_id;
          data.folders[0].child_folders.forEach((folder) => {
            const itemObject = {
              id: folder.folder_id,
              type: 'folder',
              name: folder.name,
              listed: folder.listed,
              published: null,
              order: folder.order_index,
              group_id: groupId,
              slug: folder.slug,
              projectId: null,
              pageId: null,
              tags: null,
              image: null,
              isSharedTemplate: false,
              incompatibleItem: false,
              lastUpdated: folder.updated_at,
              created: folder.created_at,
              groupDomain: this.groupDomain,
              childPages: null,
              item_path: folder.item_path,
            };
            const teamRoles = this.getTheTeamRolesForItem(folder.item_path);
            const itemAllRoleIds = [...new Set([
              ...userGroupRoleIds,
              ...teamRoles,
            ])];
            itemObject.privilegesForItem = this.getAllPrivilegesByRoleIds(itemAllRoleIds);
            this.projectAndFolderItems.push(itemObject);
            itemsArray.push(itemObject);
            this.slugsOfAllChildItems.push(folder.slug);
          });
          data.folders[0].child_projects.forEach((project) => {
            const image = (project.thumbnails?.cover_image?.url)
              ? `url(${project.thumbnails.cover_image.url})`
              : null;
            const focalpoint = (project.thumbnails?.cover_image?.focalpoint)
              ? `${project.thumbnails.cover_image.focalpoint.x}% ${project.thumbnails.cover_image.focalpoint.y}%`
              : null;
            const itemObject = {
              id: project.project_id,
              type: 'project',
              name: project.name,
              published: project.published,
              order: project.order_index,
              group_id: groupId,
              slug: project.slug,
              projectId: project.project_id,
              pageId: null,
              searchable: project.searchable,
              listed: project.listed,
              isSharedTemplate: project.is_shared_template,
              image,
              focalpoint,
              tags: this.getAllTags(project, 'project'),
              incompatibleItem: project.incompatible_item,
              lastUpdated: project.updated_at,
              created: project.created_at,
              groupDomain: this.groupDomain,
              childPages: project.child_pages,
              item_path: project.item_path,
            };
            const teamRoles = this.getTheTeamRolesForItem(project.item_path);
            const itemAllRoleIds = [...new Set([
              ...userGroupRoleIds,
              ...teamRoles,
            ])];
            itemObject.privilegesForItem = this.getAllPrivilegesByRoleIds(itemAllRoleIds);
            itemsArray.push(itemObject);
            this.projectAndFolderItems.push(itemObject);
            this.slugsOfAllChildItems.push(project.slug);
          });
          data.folders[0].child_pages.forEach((page) => {
            const focalpoint = (page.thumbnails?.image?.focalpoint)
              ? `${page.thumbnails.image.focalpoint.x}% ${page.thumbnails.image.focalpoint.y}%`
              : null;
            const updatedBy = (page && page.latest_page_version?.user?.email)
              ? page.latest_page_version.user.email
              : null;
            const itemObject = {
              id: page.page_id,
              type: 'page',
              name: page.name,
              published: page.published,
              order: pageOrder,
              group_id: groupId,
              slug: page.slug,
              projectId: null,
              pageId: page.page_id,
              searchable: page.searchable,
              latestPageVersionId: page.latest_page_version_id,
              latestPublishedPageVersionId: page.latest_published_page_version_id,
              listed: page.listed,
              image: null,
              isSharedTemplate: page.is_shared_template,
              focalpoint,
              tags: this.getAllTags(page, 'page'),
              keywords: page.keywords,
              incompatibleItem: page.incompatible_item,
              lastUpdated: page.updated_at,
              created: page.created_at,
              groupDomain: this.groupDomain,
              updatedBy,
              item_path: page.item_path,
            };
            const teamRoles = this.getTheTeamRolesForItem(page.item_path);
            const itemAllRoleIds = [...new Set([
              ...userGroupRoleIds,
              ...teamRoles,
            ])];
            itemObject.privilegesForItem = this.getAllPrivilegesByRoleIds(itemAllRoleIds);
            itemsArray.push(itemObject);
            itemObject.image = this.getImageUrl(page.thumbnails);
            this.pageItems.push(itemObject);
            this.slugsOfAllChildItems.push(page.slug);
            pageOrder++;
          });
          // run the dashboard item tour if a project or page exists
          if (data.folders[0].child_pages.length || data.folders[0].child_projects.length) {
            this.dashboardItemTour();
          }
        }
        if (data.projects && data.projects.length > 0) {
          data.projects[0].child_pages.forEach((page) => {
            const focalpoint = (page.thumbnails?.image?.focalpoint)
              ? `${page.thumbnails.image.focalpoint.x}% ${page.thumbnails.image.focalpoint.y}%`
              : null;
            const updatedBy = (page && page.latest_page_version?.user?.email)
              ? page.latest_page_version.user.email
              : null;
            const itemObject = {
              id: page.page_id,
              type: 'page',
              name: page.name,
              published: page.published,
              latestPageVersionId: page.latest_page_version_id,
              latestPublishedPageVersionId: page.latest_published_page_version_id,
              order: pageOrder,
              group_id: page.group_id,
              slug: page.slug,
              projectId: null,
              pageId: page.page_id,
              searchable: page.searchable,
              listed: page.listed,
              image: null,
              isSharedTemplate: page.is_shared_template,
              focalpoint,
              tags: this.getAllTags(page, 'page'),
              incompatibleItem: page.incompatible_item,
              lastUpdated: page.updated_at,
              created: page.created_at,
              groupDomain: this.groupDomain,
              updatedBy,
              item_path: page.item_path,
            };
            const teamRoles = this.getTheTeamRolesForItem(page.item_path);
            const itemAllRoleIds = [...new Set([
              ...userGroupRoleIds,
              ...teamRoles,
            ])];
            itemObject.privilegesForItem = this.getAllPrivilegesByRoleIds(itemAllRoleIds);
            itemObject.image = this.getImageUrl(page.thumbnails);
            itemsArray.push(itemObject);
            this.pageItems.push(itemObject);
            this.slugsOfAllChildItems.push(page.slug);
            pageOrder++;
          });
          if (data.projects[0].child_pages.length) {
            this.dashboardItemTour();
          }
        }
        this.projectAndFolderItems = this.projectAndFolderItems.sort((a, b) => a.order - b.order);
        itemsArray = itemsArray.sort((a, b) => a.order - b.order);
        return (itemsArray) || null;
      },
      skip() {
        // skip this query when there is no itemId or the item type is page
        return !this.currentItem.id || this.currentItem.type === 'page' || !this.groupDomain;
      },
      fetchPolicy: 'network-only',
    },
    isPreviewWindowOpen: {
      query: GetPreviewWindowFromCache,
      update(data) {
        if (data.previewwWindow
          && data.previewwWindow.isPreviewWindowOpen) {
          // the preview button is pressed so call the preview modal window
          return true;
        }
        return false;
      },
    },
    showShowUrlModal: {
      query: GetItemUrlWindowFromCache,
      update(data) {
        if (data.itemUrlWindow?.openItemUrlWindow === true
          && data.itemUrlWindow?.itemPath === null) {
          // the show url button is pressed so call the show url modal window
          return true;
        }
        return false;
      },
    },
    groupDomain: {
      query: GetGroupSlugByGroupId,
      variables() {
        return {
          groupId: this.groupId,
        };
      },
      update(data) {
        if (data.groups.length) {
          const groupDomain = data.groups[0]?.domain;
          this.iframeSourceForPreview = `${groupDomain}/${this.$route.params.parametersOfUrl}/`;
          this.previewActionObject = {
            slug: this.slugOfPage,
            groupSlug: data.groups[0]?.slug,
            parentSlugPath: this.parentSlugPath,
          };
          this.groupSlug = data.groups[0]?.slug;
          this.groupPath = data.groups[0]?.group_path;
          return groupDomain;
        }
        return null;
      },
      skip() {
        return !this.groupId;
      },
      fetchPolicy: 'network-only',
    },
    itemName: {
      query: GetItemName,
      variables() {
        return {
          id: this.currentItem.id,
        };
      },
      update(data) {
        if (data.folders_by_pk?.name) {
          return data.folders_by_pk.name;
        }
        if (data.projects_by_pk?.name) {
          return data.projects_by_pk.name;
        }
        return null;
      },
      skip() {
        return !this.currentItem.id || !this.currentItem.type;
      },
      fetchPolicy: 'network-only',
    },
    queryChildPagesOfProject: {
      query: GetAllChildPagesOfProject,
      variables() {
        return {
          projectId: 88888888,
        };
      },
      update(data) {
        return (data.projects_by_pk?.child_pages)
          ? data.projects_by_pk.child_pages
          : null;
      },
      fetchPolicy: 'network-only',
    },
    allAvailableLanguages: {
      query: GetAllAvailableLanguages,
      update(data) {
        return data.languages;
      },
    },
    dataFromTheDBForPublishing: {
      query() {
        return (this.selectedItemForAction.type === 'project')
          ? GetProjectWithChildPagesDataToPublish
          : GetPageDataToPublish;
      },
      variables() {
        return (this.selectedItemForAction.type === 'project')
          ? { projectId: this.selectedItemForAction.id }
          : { pageId: this.selectedItemForAction.id };
      },
      update(data) {
        // reset the publish variable to set the query on hold
        this.doPublishTheSelectedItem = false;
        // call the publish data method here so we can send
        // the data from the database to the server
        const publishData = (this.selectedItemForAction.type === 'project')
          ? data.projects_by_pk
          : data.pages_by_pk;
        this.publishItem(publishData);
      },
      skip() {
        // run this query only when we set the 'doPublishTheSelectedItem' variable
        return !this.doPublishTheSelectedItem || !this.selectedItemForAction || this.selectedItemForAction.type === 'folder';
      },
      fetchPolicy: 'network-only',
    },
    isPublishedStatusQuery: {
      query: GetProjectHasToBePublishedFromCache,
      update(data) {
        if (data.projectHasToBePublished.publishButtonIsPressed) {
          // here we check the publish button status from the header
          // and start the publish process with a flag
          // and set the selected item
          this.selectedItemForAction = {
            id: this.currentItem.id,
            type: this.currentItem.type,
          };
          if (this.currentItem.type === 'project'
            && data.projectHasToBePublished.publishButtonIsPressed === true
            && data.projectHasToBePublished.hasChanged === true
          ) {
            this.publishChangedProjectData = true;
          } else {
            this.publishChangedProjectData = false;
          }
          // if there are no changes in the project call the publish modal
          if (this.currentItem.type === 'project' && !this.publishChangedProjectData) {
            this.showPublishModal = true;
          } else {
            this.doPublishTheSelectedItem = true;
          }
        }
      },
    },
    isProjectPublishedQuery: {
      query: GetProjectPublishStatus,
      variables() {
        return {
          projectId: this.currentItem.id,
        };
      },
      update(data) {
        if (data.projects_by_pk) {
          // show header button if project is not published
          if (data.projects_by_pk.published === false) {
            this.setTheDataForTheHeaderPublishButton(false, false);
          }
          this.isProjectPublished = data.projects_by_pk.published;
        }
        return data;
      },
      fetchPolicy: 'network-only',
      skip() {
        return this.currentItem.id && this.currentItem.type !== 'project';
      },
    },
    statusOfPageVersions: {
      query: GetPublishStatusOfPageVersions,
      variables() {
        return {
          projectId: this.currentItem.id,
        };
      },
      update(data) {
        // check if the page versions have the false status and if so set the publish status
        if (data.projects_by_pk?.child_pages.length) {
          const childPages = data.projects_by_pk.child_pages;
          let publishStatus = true;
          // eslint-disable-next-line consistent-return
          childPages.forEach((childPage) => {
            if (
              childPage.published === true
              && childPage.latest_page_version.published === false
            ) {
              this.setTheDataForTheHeaderPublishButton(true, true);
              publishStatus = false;
              return false;
            }
          });
          return publishStatus;
        }
        return true;
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.currentItem.id || this.currentItem.type !== 'project' || this.isProjectPublished !== true;
      },
    },
  },
  methods: {
    getTheTeamRolesForItem(itemPath) {
      const teamRoles = [];
      this.userObject.teamItemsOfUser.forEach((teamObject) => {
        teamObject.team.teams_items.some((teamItem) => {
          let teamItemItemPath = null;
          if (teamItem.folder) {
            teamItemItemPath = teamItem.folder.item_path;
          }
          if (teamItem.project) {
            teamItemItemPath = teamItem.project.item_path;
          }
          if (teamItem.page) {
            teamItemItemPath = teamItem.page.item_path;
          }
          if (itemPath.search(teamItemItemPath) !== -1) {
            // check if the role already exists in the team role array
            // or in the user group role array
            if (
              !this.userObject.userGroupRoleIds.includes(teamObject.role_id)
              && !teamRoles.includes(teamObject.role_id)
            ) {
              teamRoles.push(teamObject.role_id);
            }
            return true;
          }
          return false;
        });
      });
      return teamRoles;
    },
    getAllPrivilegesByRoleIds(userRoles) {
      let allCurrentPrivilegesForGroup = [];
      userRoles.forEach((roleId) => {
        // get the entry with the roleId
        const {
          roles_privileges: privilegesOfRole,
        } = this.allAvailablePrivileges.find((priv) => priv.role_id === roleId);
        const allPrivilegesOfRolesByName = privilegesOfRole.map((item) => item.privilege.name);
        allCurrentPrivilegesForGroup = [...new Set([
          ...allCurrentPrivilegesForGroup,
          ...allPrivilegesOfRolesByName,
        ])];
      });
      return allCurrentPrivilegesForGroup;
    },
    sortPageChanged() {
      this.rerenderPageTableDropdownKey++;
    },
    sortProjectAndFolderChanged() {
      this.rerenderProjectAndFolderDropdownKey++;
    },
    closeShowUrlModal() {
      this.$apollo.mutate({
        mutation: SetItemUrlWindowFromCache,
        variables: {
          openItemUrlWindow: false,
          itemPath: null,
        },
      });
    },
    publishItemCancel() {
      this.setTheDataForTheHeaderPublishButton(false, false);
      this.showPublishModal = false;
    },
    publishItemFromHeaderCall() {
      this.doPublishTheSelectedItem = true;
      this.showPublishModal = false;
    },
    makeToast(tableName) {
      const h = this.$createElement;
      const id = `my-toast-${this.count++}`;
      // Create the custom message with button
      const vNodesMsg = h(
        'p',
        [
          this.$t('webmag.dragAndDropMessage'),
          h('br'),
          h('b-button', {
            class: ['mt-2'],
            on: {
              click: () => {
                if (tableName === 'pages') {
                  this.resetPageTable();
                } else {
                  this.resetProjectAndFolderTale();
                }
                this.$bvToast.hide(id);
              },
            },
          }, 'Reset table'),
        ],
      );
      this.$bvToast.toast([vNodesMsg], {
        id,
        title: 'Webmag',
        toaster: 'b-toaster-top-right',
        solid: true,
        autoHideDelay: 3000,
      });
    },
    resetPageTable() {
      this.filter = '';
      this.tablesAreFiltered = false;
      this.pageTableIsSorted = false;
      this.pageTableKey++;
      this.$refs.pageTable.refresh();
    },
    resetProjectAndFolderTale() {
      this.filter = '';
      this.tablesAreFiltered = false;
      this.folderAndProjectTableIsSorted = false;
      this.projectAndFolderTableKey++;
      this.$refs.projectAndFolderTable.refresh();
    },
    searchFieldTriggered(value) {
      this.rerenderProjectAndFolderDropdownKey++;
      this.rerenderPageTableDropdownKey++;
      this.tablesAreFiltered = value !== '';
    },
    sortedProjectAndFolderTable(columnName) {
      const listOfSortItems = this.fields.filter((item) => item.sortable === true);
      this.folderAndProjectTableIsSorted = !!listOfSortItems.find(
        (item) => item.key === columnName,
      );
    },
    sortedPageTable(columnName) {
      const listOfSortItems = this.fields.filter((item) => item.sortable === true);
      this.pageTableIsSorted = !!listOfSortItems.find((item) => item.key === columnName);
    },
    async sortThePageArrayAfterDragAndDrop(pagesArray, newOrderedItemsArray) {
      // send the elements to the server with apollo
      // to update multiple rows in the page table we have to add data for the db constrains
      await Promise.all(pagesArray.map(async (item) => {
        await this.$apollo.mutate({
          mutation: SetOrderOfPagesOnDashboard,
          variables: {
            pageId: item.page_id,
            orderIndex: item.order_index,
          },
        });
      }));
      const slug_path = (this.$route.path[0] === '/')
        ? this.$route.path.substring(1, this.$route.path.length)
        : this.$route.path;
      const domain = this.groupDomain;
      await this.$apollo.mutate({
        mutation: DeleteCacheFetchMagazine,
        variables: {
          domain: (domain[domain.length - 1] === '/') ? domain : `${domain}/`,
          slug_path,
        },
      });
      // update the items array and update the data-table
      this.pageItems = [...newOrderedItemsArray];
      this.pageTableKey++;
      this.$refs.pageTable.refresh();
    },
    async sortTheProjectAndFolderArrayAfterDragAndDrop(
      projectAndFolderArray,
      newOrderedItemsArray,
    ) {
      // send the elements to the server with apollo
      // to update multiple rows in the page table we have to add data for the db constrains
      await Promise.all(projectAndFolderArray.map(async (item) => {
        if (item.type === 'folder') {
          await this.$apollo.mutate({
            mutation: SetOrderOfFoldersOnDashboard,
            variables: {
              folderId: item.item_id,
              orderIndex: item.order_index,
            },
          });
        }
        if (item.type === 'project') {
          await this.$apollo.mutate({
            mutation: SetOrderOfProjectsOnDashboard,
            variables: {
              projectId: item.item_id,
              orderIndex: item.order_index,
            },
          });
        }
      }));
      // update the items array and update the data-table
      this.projectAndFolderItems = [...newOrderedItemsArray];
      this.projectAndFolderTableKey++;
      this.$refs.projectAndFolderTable.refresh();
    },
    // this action is responsible for the dotdotdot menu
    itemAction(actionType, item) {
      // set the item to the selected item
      this.selectedItemForAction = { ...item };
      this.selectedItemForAction.parent_slug_path = this.$route.fullPath;

      switch (actionType) {
        case 'updateProject':
          this.updateProject = true;
        // eslint-disable-next-line no-fallthrough
        case 'publish':
          this.publishTriggeredFromDotDropdown = true;
          // set the variable to run the query!
          this.doPublishTheSelectedItem = true;
          break;
        case 'unPublish':
          this.unpublishItem();
          break;
        case 'delete':
          this.deleteItem();
          break;
        case 'moved':
        case 'duplicated':
          this.refreshItemsQuery();
          break;
        default:
          console.log('action Type data', actionType, item);
      }
    },
    changeDashboardView(view) {
      if (view === 'table') {
        this.isTableView = true;
        this.$localStorage.set('isDashboardTableView', true);
      }
      if (view === 'cards') {
        this.isTableView = false;
        this.pageTableIsSorted = false;
        this.pageTableKey++;
        if (this.$refs.pageTable) {
          this.$refs.pageTable.refresh();
        }
        this.$localStorage.set('isDashboardTableView', false);
      }
    },
    navigateToItem(item) {
      if (item.incompatibleItem === true && !this.userObject.isSysAdmin) {
        this.$refs.incompatibleModalComponent.$refs.incompatibleItem.show();
      } else {
        const newPath = (this.$route.params.parametersOfUrl && this.$route.params.parametersOfUrl !== '/')
          ? `/${this.$route.params.parametersOfUrl}/${item.slug}`
          : `/${item.slug}`;
        this.$router.push(newPath);
      }
    },
    getImageUrl(thumbnail) {
      let cloudImageUrl = null;
      if (thumbnail?.image?.url) {
        cloudImageUrl = this.$makeCloudImage(
          thumbnail.image.url,
          {
            width: 210,
            height: 150,
          },
          thumbnail.image,
        );
      }
      return (thumbnail?.image?.url)
        ? `url(${cloudImageUrl})`
        // eslint-disable-next-line global-require
        : `url(${require('@/assets/thumb-default.png')})`;
    },
    getAllTags(item, itemType) {
      const allTags = [];
      const tagItemName = (itemType === 'page')
        ? 'pages_tags'
        : 'projects_tags';
      item[tagItemName].forEach((tagItem) => {
        if (!allTags.includes(tagItem.tag.name)) {
          allTags.push(tagItem.tag.name);
        }
      });
      if (item[tagItemName].length === 0) {
        return null;
      }
      return allTags;
    },
    async publishItem(data) {
      if (data) {
        await this.$apollo.mutate({
          mutation: SetLoadingIndicatorInCache,
          variables: {
            isIndicatorLoading: true,
          },
        });
        const dataToBeSendToTheServer = data;
        let actionName = null;
        let languageId = null;
        let localNameObject = null;
        const allPageVersionIdsOfPublishedData = [];
        switch (this.selectedItemForAction.type) {
          case 'project':
            // prepare the data object
            actionName = 'publishProject';
            // set the flag for publish to be true because we send data
            // first to PHP API and then write in the DB
            // and also for all child items
            dataToBeSendToTheServer.published = true;
            dataToBeSendToTheServer.updateProject = this.updateProject;
            languageId = dataToBeSendToTheServer.language_id;
            localNameObject = this.allAvailableLanguages.find(
              (languageItem) => languageItem.language_id === languageId,
            );
            dataToBeSendToTheServer.locale = (localNameObject)
              ? localNameObject.handle
              : null;
            for (let i = 0; i < dataToBeSendToTheServer.child_pages.length; i++) {
              languageId = dataToBeSendToTheServer.child_pages[i].language_id;
              localNameObject = this.allAvailableLanguages.find(
                // eslint-disable-next-line no-loop-func
                (languageItem) => languageItem.language_id === languageId,
              );
              dataToBeSendToTheServer.child_pages[i].locale = (localNameObject)
                ? localNameObject.handle
                : null;
              const publishChanges = !!(this.selectedItemForAction.statusChanged);
              if (this.publishChangedProjectData === true || publishChanges) {
                // only republish the already published pages
                if (dataToBeSendToTheServer.child_pages[i].published === true) {
                  dataToBeSendToTheServer.child_pages[i]
                    .latest_page_version.published = true;
                  allPageVersionIdsOfPublishedData.push(
                    dataToBeSendToTheServer
                      .child_pages[i]
                      .latest_page_version
                      .page_version_id,
                  );
                }
              } else {
                // publish all pages of project
                // check first if a page in a project exists
                dataToBeSendToTheServer.child_pages[i].published = true;
                if (dataToBeSendToTheServer.child_pages[i].latest_page_version) {
                  dataToBeSendToTheServer.child_pages[i]
                    .latest_page_version.published = true;
                  allPageVersionIdsOfPublishedData.push(
                    dataToBeSendToTheServer
                      .child_pages[i]
                      .latest_page_version
                      .page_version_id,
                  );
                }
              }
            }
            break;
          case 'page':
            // prepare the data object
            actionName = 'publishPage';
            // set the flag for publish to be true because we send data
            // first to PHP API and then write in the DB
            dataToBeSendToTheServer.published = true;
            if (dataToBeSendToTheServer.parent_folder?.corporate_identity) {
              dataToBeSendToTheServer.corporate_identity = JSON.parse(JSON.stringify(
                dataToBeSendToTheServer.parent_folder.corporate_identity,
              ));
              delete dataToBeSendToTheServer.parent_folder;
            } else if (dataToBeSendToTheServer.parent_project?.corporate_identity) {
              dataToBeSendToTheServer.corporate_identity = JSON.parse(JSON.stringify(
                dataToBeSendToTheServer.parent_project.corporate_identity,
              ));
              delete dataToBeSendToTheServer.parent_project;
            }
            // publish also page versions
            dataToBeSendToTheServer.latest_page_version.published = true;
            allPageVersionIdsOfPublishedData.push(
              dataToBeSendToTheServer.latest_page_version.page_version_id,
            );
            // get the name of the locale by the locale id
            languageId = dataToBeSendToTheServer.language_id;
            localNameObject = this.allAvailableLanguages.find(
              (languageItem) => languageItem.language_id === languageId,
            );
            dataToBeSendToTheServer.locale = (localNameObject)
              ? localNameObject.handle
              : null;
            break;
          default:
            console.log(this.selectedItemForAction);
        }
        try {
          // check if project is published from header or dot dropdown
          const parent_slug_path = (this.publishTriggeredFromDotDropdown)
            ? this.selectedItemForAction.parent_slug_path
            : this.parentSlugPath;
          const publishedDataWasSuccessfulSend = await this.sendPublishedDataToServer({
            action: actionName,
            data: {
              parent_slug_path,
              group_slug: this.groupSlug,
              ...dataToBeSendToTheServer,
            },
          });
          if (publishedDataWasSuccessfulSend) {
            // if the updateProject flag is set we don't update the database!
            if (this.updateProject) {
              this.updateProject = false;
            } else {
              await this.setThePublishStateOfProjectOrPageInDB(true);
              // set the publishing state of all page versions!!!
              const pageVersionsObject = await this.setThePublishStateOfPageVersions(
                allPageVersionIdsOfPublishedData,
                true,
              );
              // allPageVersionIdsOfPublishedData das sind meine PageIds
              const publicationLogs = [];
              if (this.selectedItemForAction.type === 'project') {
                const updatedPageVersions = pageVersionsObject.data.update_page_versions.returning;
                if (updatedPageVersions.length) {
                  updatedPageVersions.forEach((pageVersion) => {
                    publicationLogs.push({
                      page_id: pageVersion.page_id,
                      page_version_id: pageVersion.page_version_id,
                      project_id: this.selectedItemForAction.id,
                      published_at: pageVersion.published_at,
                      user_id: this.userObject.userId,
                    });
                  });
                }
              } else {
                // page is published
                const pageVersionId = pageVersionsObject
                  .data.update_page_versions.returning[0].page_version_id;
                const pageVersionPublished = pageVersionsObject
                  .data.update_page_versions.returning[0].published_at;
                publicationLogs.push({
                  page_id: this.selectedItemForAction.pageId,
                  page_version_id: pageVersionId,
                  published_at: pageVersionPublished,
                  user_id: this.userObject.userId,
                });
              }
              // mutation to write the insert statements for logging the published content
              await this.$apollo.mutate({
                mutation: InsertPublicationLogs,
                variables: {
                  objects: publicationLogs,
                },
              });
              if (this.currentItem.type === 'project') {
                this.isProjectPublished = true;
              }
              // after publishing open the success modal!
              const projectWasPublished = (
                this.selectedItemForAction.type === 'project' && !this.publishChangedProjectData
              );
              if (this.publishTriggeredFromDotDropdown || projectWasPublished) {
                // check if project published from header or from dot dropdown
                if (!this.selectedItemForAction.slug) {
                  this.selectedItemForAction.slug = this.slugOfPage;
                  this.selectedItemForAction.parent_slug_path = this.parentSlugPath;
                  this.selectedItemForAction.groupDomain = this.groupDomain;
                }
                const slugPath = (this.selectedItemForAction.parent_slug_path === '/')
                  ? `/${this.selectedItemForAction.slug}`
                  : `${this.selectedItemForAction.parent_slug_path}/${this.selectedItemForAction.slug}`;
                this.publishedItem = {
                  slug: this.selectedItemForAction.slug,
                  type: this.selectedItemForAction.type,
                  groupDomain: this.selectedItemForAction.groupDomain,
                  slugPath,
                };
                this.showPublishSuccessModal = true;
                await this.$apollo.mutate({
                  mutation: SetLoadingIndicatorInCache,
                  variables: {
                    isIndicatorLoading: false,
                  },
                });
                this.publishTriggeredFromDotDropdown = false;
                // delete the cache
                const domain = this.selectedItemForAction.groupDomain;
                let parent_path = this.selectedItemForAction.parent_slug_path;
                if (parent_path.length > 0) {
                  parent_path = (parent_path[0] === '/')
                    ? parent_path.substring(1, parent_path.length)
                    : parent_path;
                }
                let slug_path = (parent_path)
                  ? `${parent_path}/${this.selectedItemForAction.slug}`
                  : this.selectedItemForAction.slug;
                slug_path = slug_path.substring(0, slug_path.lastIndexOf('/'));
                await this.$apollo.mutate({
                  mutation: DeleteCacheFetchMagazine,
                  variables: {
                    domain: (domain[domain.length - 1] === '/') ? domain : `${domain}/`,
                    slug_path,
                  },
                });
              } else {
                // delete the cache
                const domain = this.groupDomain;
                let parent_path = this.parentSlugPath;
                if (parent_path.length > 0) {
                  parent_path = (parent_path[0] === '/')
                    ? parent_path.substring(1, parent_path.length)
                    : parent_path;
                }
                await this.$apollo.mutate({
                  mutation: DeleteCacheFetchMagazine,
                  variables: {
                    domain: (domain[domain.length - 1] === '/') ? domain : `${domain}/`,
                    slug_path: parent_path,
                  },
                });
              }
              await this.refreshItemsQuery();
            }
          }
          await this.$apollo.mutate({
            mutation: SetLoadingIndicatorInCache,
            variables: {
              isIndicatorLoading: false,
            },
          });
        } catch (ex) {
          console.log(ex);
          await this.$apollo.mutate({
            mutation: SetLoadingIndicatorInCache,
            variables: {
              isIndicatorLoading: false,
            },
          });
        }
      }
    },
    async unpublishItem() {
      let actionName = null;
      let childPagesOfProject = null;
      let result = null;
      // eslint-disable-next-line default-case
      switch (this.selectedItemForAction.type) {
        case 'project':
          // prepare the data object
          actionName = 'unpublishProject';
          // get the childPagesOfProject
          result = await this.$apollo.queries.queryChildPagesOfProject.refetch({
            projectId: this.selectedItemForAction.id,
          });
          childPagesOfProject = (result?.data.projects_by_pk.child_pages)
            ? result.data.projects_by_pk.child_pages
            : null;
          break;
        case 'page':
          // prepare the data object
          actionName = 'unpublishPage';
          break;
      }
      try {
        const publishedDataWasSuccessfulSend = await this.sendPublishedDataToServer({
          action: actionName,
          data: {
            slug: this.selectedItemForAction.slug,
            parent_slug_path: this.selectedItemForAction.parent_slug_path,
            group_slug: this.groupSlug,
            group_domain: this.selectedItemForAction.groupDomain,
            child_pages: childPagesOfProject,
          },
        });
        if (publishedDataWasSuccessfulSend) {
          await this.setThePublishStateOfProjectOrPageInDB(false);
          // delete the cache
          const domain = this.selectedItemForAction.groupDomain;
          let { parent_slug_path } = this.selectedItemForAction;
          if (parent_slug_path.length > 0) {
            parent_slug_path = (parent_slug_path[0] === '/')
              ? parent_slug_path.substring(1, parent_slug_path.length)
              : parent_slug_path;
          }
          let slug_path = (parent_slug_path)
            ? `${parent_slug_path}/${this.selectedItemForAction.slug}`
            : this.selectedItemForAction.slug;
          slug_path = slug_path.substring(0, slug_path.lastIndexOf('/'));
          await this.$apollo.mutate({
            mutation: DeleteCacheFetchMagazine,
            variables: {
              domain: (domain[domain.length - 1] === '/') ? domain : `${domain}/`,
              slug_path,
            },
          });
          await this.refreshItemsQuery();
        }
      } catch (ex) {
        console.log(ex);
      }
    },
    async deleteItem() {
      let folderId = null;
      const {
        projectId, pageId, type, id, slug,
      } = this.selectedItemForAction;
      if (type === 'folder') {
        folderId = id;
      }
      // prepare the data object for the server
      const dataForServer = {
        data: {
          slug,
          parent_slug_path: this.selectedItemForAction.parent_slug_path,
          group_slug: this.groupSlug,
          group_domain: this.selectedItemForAction.groupDomain,
        },
      };
      let obj = null;
      // eslint-disable-next-line default-case
      switch (type) {
        case 'folder':
          // prepare the data object
          dataForServer.action = 'deleteFolder';
          obj = { folder_id: folderId };
          break;
        case 'project':
          // prepare the data object
          dataForServer.action = 'deleteProject';
          obj = { project_id: projectId };
          break;
        case 'page':
          // prepare the data object
          dataForServer.action = 'deletePage';
          obj = { page_id: pageId };
          break;
      }
      // send data to server
      try {
        const publishedDataWasSuccessfulSend = await this
          .sendPublishedDataToServer(dataForServer);
        if (publishedDataWasSuccessfulSend) {
          await this.$apollo.mutate({
            mutation: DeleteItem,
            variables: {
              obj,
            },
          });
          await this.$apollo.mutate({
            mutation: SetLoadingIndicatorInCache,
            variables: {
              isIndicatorLoading: false,
            },
          });
          // delete the cache
          const domain = this.selectedItemForAction.groupDomain;
          let { parent_slug_path } = this.selectedItemForAction;
          if (parent_slug_path.length > 0) {
            parent_slug_path = (parent_slug_path[0] === '/')
              ? parent_slug_path.substring(1, parent_slug_path.length)
              : parent_slug_path;
          }
          let slug_path = (parent_slug_path)
            ? `${parent_slug_path}/${this.selectedItemForAction.slug}`
            : this.selectedItemForAction.slug;
          slug_path = slug_path.substring(0, slug_path.lastIndexOf('/'));
          await this.$apollo.mutate({
            mutation: DeleteCacheFetchMagazine,
            variables: {
              domain: (domain[domain.length - 1] === '/') ? domain : `${domain}/`,
              slug_path,
            },
          });
          await this.refreshItemsQuery();
        }
      } catch (ex) {
        console.error(ex);
      }
    },
    getChildPageIdsOfDBData(data) {
      const projectIdsArray = [];
      this.publishedChildPageIdsOfDB = [];
      if (data.projects[0]?.child_pages) {
        const childPages = data.projects[0].child_pages;
        childPages.forEach((childPage) => {
          if (childPage.page_id) {
            projectIdsArray.push(childPage.page_id);
            if (childPage.page_id && childPage.published === true) {
              this.publishedChildPageIdsOfDB.push(childPage.page_id);
            }
          }
        });
      }
      return (projectIdsArray.length)
        ? projectIdsArray
        : null;
    },
    setThePublishStateOfPageVersions(pageVersionIds, publishStatus) {
      return new Promise((resolve, reject) => {
        try {
          this.$apollo.mutate({
            mutation: UpdatePageVersionsPublishState,
            variables: {
              pageVersionIds,
              publishStatus,
              userId: this.userObject.userId,
            },
          }).then((result) => {
            resolve(result);
          });
        } catch (e) {
          console.log(e);
          reject(e);
        }
      });
    },
    async setThePublishStateOfProjectOrPageInDB(published) {
      const mutationQuery = (this.selectedItemForAction.type === 'project')
        ? SetPublishStateOfProject
        : SetPublishStateOfPageById;
      const updatedProjectOrPage = await this.$apollo.mutate({
        mutation: mutationQuery,
        variables: {
          id: this.selectedItemForAction.id,
          published,
        },
      });
      if (this.selectedItemForAction.type === 'project') {
        // publish only published items
        const result = await this.$apollo.query({
          query: GetAllChildPageIdsOfProject,
          variables: {
            projectId: this.selectedItemForAction.id,
          },
          fetchPolicy: 'network-only',
        });
        let childPageIdsOfProject = this.getChildPageIdsOfDBData(result.data);
        if (childPageIdsOfProject) {
          // check also fot the published elements
          if (this.selectedItemForAction.statusChanged === true) {
            childPageIdsOfProject = this.publishedChildPageIdsOfDB;
          }
          // do the update only if the project is published, not if the data changes!!!
          if (this.publishChangedProjectData === false) {
            // set the publishing state of all child pages of project
            await this.$apollo.mutate({
              mutation: UpdatePagePublishFlag,
              variables: {
                pageIds: childPageIdsOfProject,
                isPublished: published,
              },
            });
          }
        }
        return updatedProjectOrPage.data.update_projects_by_pk;
      }
      return updatedProjectOrPage.data.update_pages_by_pk;
    },
    setTheDataForTheHeaderPublishButton(isPublished, hasChanged) {
      this.$apollo.mutate({
        mutation: SetProjectHasToBePublishedInCache,
        variables: {
          isPublished,
          hasChanged,
          publishButtonIsPressed: false,
        },
      });
    },
    async refreshItemsQuery() {
      if (this.projectId !== null && this.isProjectPublished) {
        this.setTheDataForTheHeaderPublishButton(true, false);
      }
      await this.$apollo.queries.items.refetch();
      await this.$apollo.queries.statusOfPageVersions.refetch();
      // the counter is used for the keys of the dashboard items to force them to rerender
      this.counter = this.counter + 1000;
    },
    showTooltipOfTags(tags) {
      if (tags && Array.isArray(tags)) {
        const tagStringNumber = tags.join('').length;
        if (tagStringNumber > 17) {
          return true;
        }
      }
      return false;
    },
  },
  beforeDestroy() {
    // reset the header query if you leave the component
    this.setTheDataForTheHeaderPublishButton(true, false);
  },
};
</script>

<style lang="scss" scoped>

.draggable-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.table-dark {
  background-color: transparent;
}

/deep/ table.table-view {
  font-size: 15px;
  border-collapse: separate;
  border-spacing: 0 8px;

  thead th {
    text-transform: uppercase;
    font-size: 13px;

    &:nth-child(2), &:nth-child(7) {
      text-align: center;
    }
  }

  tbody tr {
    background-color: #53575B;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);

    > td {
      padding: 7px 8px;

      &:first-child {
        padding: 0;
      }

      &:nth-child(2) {
        text-align: center;
        font-weight: 700;
      }

      &:nth-child(4), &:nth-child(5) {
        overflow: hidden;

        ul {
          list-style: none;
          padding-left: 0;
          margin-bottom: 0;

          li {
            display: block;
            padding: 0 5px;
            margin-right: 6px;
            font-size: 13px;
            font-weight: 700;
            text-transform: uppercase;
            white-space: nowrap;
             overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      &:nth-child(4) {
        ul {
          li {
            color: #44484C;
            background-color: #c0c0c0;
          }
        }
      }

      &:nth-child(5) {
        ul {
          li {
            color: #fff;
            background-color: #777c7f;
          }
        }
      }

      &:nth-child(8) {
        text-align: center;
      }

      &:nth-child(9) {
        padding: 0;

        button {
          width: 40px;
          min-height: 36px;
        }
      }

      &:last-child {
        xdisplay: none;
      }
    }
  }

  .cell-background-image {
    width: 100%;
    height: 100%;
    min-height: 36px;
    min-width: 50px;
    background-color: #777C7F;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .cell-folder {
    background: rgba(83, 220, 134, 0.7);

    svg {
      fill: white;
    }
  }
}

/deep/ .btn.dot-button {
  color: #b9b9b9;
  background: transparent;
  border: none;
  padding: 5px;

  &:hover {
    color: #fff;
  }
}

/deep/ table {
  tr {
    outline: none;
    cursor: pointer;
  }
}

/deep/ .table-view .btn.dot-button {
  padding: 0;
  line-height: 1;

  text-align: center;
  vertical-align: text-top;
}

/deep/ table.card-view {
  thead {
    display: none;
  }

  tbody {
    display: flex;
    flex-wrap: wrap;

    tr > td {
      display: none;

      &:last-child {
        display: block;
        padding: 0;
      }
    }
  }
}

.tooltip-inner {
  ul.tags-tooltip {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    text-align: center;

    li {
      display: inline-block;
      padding: 0 5px;
      margin-top: 2px;
      margin-bottom: 2px;
      margin-right: 6px;
      font-size: 13px;
      color: #44484C;
      background-color: #B9B9B9;
      text-transform: uppercase;
    }

  }
}

.search-group {
  width: 200px;
  background-color: #53575B;
  padding-left: 11px;
}

#filter-input, #filter-input:focus {
  color: #b9b9b9;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
}

#filter-input::-webkit-search-cancel-button {
  appearance: none;
  height: 23px;
  width: 20px;
  background-size: 21px;
  background: url('../../assets/svg/icon-search-cancel.svg') no-repeat center center;
  cursor: pointer;
}

button.btn.active svg {
  color: #5cb85c;
}
</style>
